import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cartReducer";
import useFetch from "../../hooks/useFetch";
import "./Product.scss";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import BalanceIcon from "@mui/icons-material/Balance";
import FeaturedProducts from "../../components/FeaturedProducts/FeaturedProducts";
import Spinner from "../../components/spiner/Spiner";

const Product = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data } = useFetch(`/products/get-product/${id}`);

  const [selectedImg, setSelectedImg] = useState(data?.images[0]);
  const [quantity, setQuantity] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  const handleImageClick = (img) => {
    setSelectedImg(img);
  };

  const handleImageLoad = () => {
    setImageLoading(false);
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const accessToken = localStorage.getItem("accessToken");

  if (!data) {
    return null; // or render a loading state or an error message
  }
  if (isLoading) {
    return <Spinner />;
  }

  const handleAddToCart = () => {
    if (accessToken) {
      setIsLoading(true);
      dispatch(
        addToCart({
          id: data?._id,
          name: data?.name,
          desc: data?.desc,
          price: data?.price,
          img: data?.images[0],
          quantity,
          cat: data?.mainCategory,
          subCat: data?.subCategory,
        })
      );
      setIsLoading(false);
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <div className="product">
        <div className="left">
          <div className="images">
            <div
              className="image-container"
              style={{
                backgroundColor: imageLoading
                  ? getRandomColor()
                  : "transparent",
              }}
            >
              <img
                src={data?.images[0]}
                alt="t-Shirt"
                onClick={() => handleImageClick(data?.images[0])}
                loading="lazy"
                onLoad={handleImageLoad}
              />
            </div>
            <div
              className="image-container"
              style={{
                backgroundColor: imageLoading
                  ? getRandomColor()
                  : "transparent",
              }}
            >
              <img
                src={data?.images[1]}
                alt="t-Shirt"
                onClick={() => handleImageClick(data?.images[1])}
                loading="lazy"
                onLoad={handleImageLoad}
              />
            </div>
            <div
              className="image-container"
              style={{
                backgroundColor: imageLoading
                  ? getRandomColor()
                  : "transparent",
              }}
            >
              <img
                src={data?.images[2]}
                alt="t-Shirt"
                onClick={() => handleImageClick(data?.images[2])}
                loading="lazy"
                onLoad={handleImageLoad}
              />
            </div>
          </div>
          <div className="mainImg">
            <div
              className="image-container"
              style={{
                backgroundColor: imageLoading
                  ? getRandomColor()
                  : "transparent",
              }}
            >
              <img
                src={selectedImg || data?.images[0]}
                loading="lazy"
                alt="t-Shirt"
                onLoad={handleImageLoad}
              />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="right">
            <h1>{data?.name}</h1>
            <span className="price">${data?.price}</span>
            <p>{data?.desc}</p>
            <div className="quantity">
              <button
                onClick={() =>
                  setQuantity((prev) => (prev === 1 ? 1 : prev - 1))
                }
              >
                -
              </button>
              {quantity}
              <button onClick={() => setQuantity((prev) => prev + 1)}>+</button>
            </div>
            <button
              className="add"
              onClick={handleAddToCart}
              disabled={isLoading}
            >
              <AddShoppingCartIcon /> {isLoading ? "Adding..." : "ADD TO CART"}
            </button>
            <div className="links">
              <Link style={{ textDecoration: "none" }}>
                <div className="item">
                  <FavoriteBorderIcon /> ADD TO WISH LIST
                </div>
              </Link>

              <div className="item">
                <BalanceIcon /> ADD TO COMPARE
              </div>
            </div>
            <div className="info">
              <span>Vendor: Polo</span>
              <span>Product Type: T-Shirt</span>
              <span>Tag: T-Shirt, Women, Top</span>
            </div>
            <hr />
            <div className="info">
              <span>DESCRIPTION</span>
              <hr />
              <span>ADDITIONAL INFORMATION</span>
              <hr />
              <span>FAQ</span>
            </div>
          </div>
        </div>
      </div>
      <FeaturedProducts type="Latest products" />
    </>
  );
};

export default Product;
