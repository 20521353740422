import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Stepper, Step, StepLabel } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { shades } from "../../theme";
import Payment from "./Payment";
import Shipping from "./Shipping";
import axiosInstance from "../../axiosInstance";
import { toast } from "react-toastify";
import Spinner from "../../components/spiner/Spiner";
import { resetCart } from "../../redux/cartReducer";

const Checkout = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const products = useSelector((state) => state.cart.products);
  const isFirstStep = activeStep === 0;
  const isSecondStep = activeStep === 1;

  const dispatch = useDispatch();
  const handleFormSubmit = async (values, actions) => {
    setActiveStep(activeStep + 1);

    // Copy the billing address to shipping address if they are the same
    if (isFirstStep && values.shippingAddress.isSameAddress) {
      actions.setFieldValue("shippingAddress", {
        ...values.billingAddress,
        isSameAddress: true,
      });
    }

    if (isSecondStep) {
      makePayment(values);
    }

    actions.setTouched({});
  };

  async function makePayment(values) {
    setIsLoading(true); // Start loading
    // Calculate the total amount from the products in the state
    const totalAmount = products.reduce((acc, product) => {
      return acc + product.price * product.quantity;
    }, 0);

    const accessToken = localStorage.getItem("accessToken");
    const token = JSON.parse(accessToken);
    const userId = token._id;

    // Prepare the data to send to the server
    const requestBody = {
      userId: userId,
      email: values.email,
      phoneNumber: values.phoneNumber,
      products: products?.map(
        ({ id, quantity, img, name, price, desc, cat, subCat }) => ({
          id: id,
          productId: id,
          quantity: quantity,
          image: img,
          name: name,
          price: price,
          desc: desc,
          cat: cat,
          subCat: subCat,
        })
      ),
      amount: totalAmount,
      billingAddress: {
        firstName: values.billingAddress.firstName,
        lastName: values.billingAddress.lastName,
        country: values.billingAddress.country,
        street1: values.billingAddress.street1,
        street2: values.billingAddress.street2,
        city: values.billingAddress.city,
        state: values.billingAddress.state,
        zipCode: values.billingAddress.zipCode,
      },
      shippingAddress: {
        firstName: values.shippingAddress.firstName,
        lastName: values.shippingAddress.lastName,
        country: values.shippingAddress.country,
        street1: values.shippingAddress.street1,
        street2: values.shippingAddress.street2,
        city: values.shippingAddress.city,
        state: values.shippingAddress.state,
        zipCode: values.shippingAddress.zipCode,
      },
    };

    // Send the data to the server
    try {
      const response = await axiosInstance.post(
        "api/orders/payment",
        requestBody
      );
      if (response.data.url) {
        window.location.href = response.data.url;
      }
      await axiosInstance.post("api/orders", requestBody);
      dispatch(resetCart());
    } catch (error) {
      console.error("Payment error:", error);
      toast.error(error.message, {
        className: "toast-message",
      });
    } finally {
      setIsLoading(false); // Stop loading
    }
  }

  const initialValues = {
    billingAddress: {
      firstName: "",
      lastName: "",
      country: "",
      street1: "",
      street2: "",
      city: "",
      state: "",
      zipCode: "",
    },
    shippingAddress: {
      isSameAddress: true,
      firstName: "",
      lastName: "",
      country: "",
      street1: "",
      street2: "",
      city: "",
      state: "",
      zipCode: "",
    },
    email: "",
    phoneNumber: "",
  };

  const checkoutSchema = [
    yup.object().shape({
      billingAddress: yup.object().shape({
        firstName: yup.string().required("required"),
        lastName: yup.string().required("required"),
        country: yup.string().required("required"),
        street1: yup.string().required("required"),
        street2: yup.string(),
        city: yup.string().required("required"),
        state: yup.string().required("required"),
        zipCode: yup.string().required("required"),
      }),
      shippingAddress: yup.object().shape({
        isSameAddress: yup.boolean(),
        firstName: yup.string().when("isSameAddress", {
          is: false,
          then: yup.string().required("required"),
        }),
        lastName: yup.string().when("isSameAddress", {
          is: false,
          then: yup.string().required("required"),
        }),
        country: yup.string().when("isSameAddress", {
          is: false,
          then: yup.string().required("required"),
        }),
        street1: yup.string().when("isSameAddress", {
          is: false,
          then: yup.string().required("required"),
        }),
        street2: yup.string().when("isSameAddress", {
          is: false,
          then: yup.string(),
        }),
        city: yup.string().when("isSameAddress", {
          is: false,
          then: yup.string().required("required"),
        }),
        state: yup.string().when("isSameAddress", {
          is: false,
          then: yup.string().required("required"),
        }),
        zipCode: yup.string().when("isSameAddress", {
          is: false,
          then: yup.string().required("required"),
        }),
      }),
    }),
    yup.object().shape({
      email: yup.string().email("Invalid email").required("required"),
      phoneNumber: yup.string().required("required"),
    }),
  ];
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Box width="80%" m="100px auto">
      <Stepper activeStep={activeStep} sx={{ m: "20px 0" }}>
        <Step>
          <StepLabel>Billing</StepLabel>
        </Step>
        <Step>
          <StepLabel>Payment</StepLabel>
        </Step>
      </Stepper>
      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema[activeStep]}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              {isFirstStep && (
                <Shipping
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              )}
              {isSecondStep && (
                <Payment
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              )}
              <Box display="flex" justifyContent="space-between" gap="50px">
                {!isFirstStep && (
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    sx={{
                      backgroundColor: shades.primary[200],
                      boxShadow: "none",
                      color: "white",
                      borderRadius: 0,
                      padding: "15px 40px",
                    }}
                    onClick={() => setActiveStep(activeStep - 1)}
                  >
                    Back
                  </Button>
                )}
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{
                    backgroundColor: shades.primary[400],
                    boxShadow: "none",
                    color: "white",
                    borderRadius: 0,
                    padding: "15px 40px",
                  }}
                  disabled={isLoading} // Disable the button when loading
                >
                  {!isSecondStep ? "Next" : "Place Order"}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default Checkout;
