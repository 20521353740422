import React from "react";
import "./Spiner.css";

import { Loader, Placeholder } from "rsuite";

const Spinner = () => (
  <div style={{ background: "white" }}>
    <Placeholder.Paragraph rows={8} />
    <Loader backdrop content="loading..." vertical />
  </div>
);

export default Spinner;
