import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Categories.scss";
import Spinner from "../spiner/Spiner";

const Categories = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading data
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <div className="categories">
      {isLoading ? (
        <Spinner /> // Render the Spinner component when loading
      ) : (
        <React.Fragment>
          <div className="col">
            <div className="row">
              <img
                src="https://images.unsplash.com/photo-1627225793904-a2f900a6e4cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzN8fHQlMjBzaGlydHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60"
                alt=""
              />
              <button>
                <Link to="/products?subCategory=T-shirt" className="link">
                  T-Shirt
                </Link>
              </button>
            </div>
            <div className="row">
              <img
                src="https://images.pexels.com/photos/11631673/pexels-photo-11631673.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
              <button>
                <Link to="/products?subCategory=Hoodie" className="link">
                  Hoodie
                </Link>
              </button>
            </div>
          </div>
          <div className="col">
            <div className="row">
              <img
                src="https://images.unsplash.com/photo-1633964124833-f4f3928c55bb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8d2ludGVyJTIwaGF0fGVufDB8fDB8fHww&auto=format&fit=crop&w=400&q=60"
                alt=""
              />
              <button>
                <Link to="/products?subCategory=Marvin%20hat" className="link">
                  Marvin Hat
                </Link>
              </button>
            </div>
          </div>
          <div className="col col-l">
            <div className="row">
              <div className="col">
                <div className="row">
                  <img
                    src="https://images.unsplash.com/photo-1620799140408-edc6dcb6d633?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjd8fHQlMjBzaGlydHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60"
                    alt=""
                  />
                  <button>
                    <Link to="/products?subCategory=Sweater" className="link">
                      Sweater
                    </Link>
                  </button>
                </div>
              </div>
              <div className="col">
                <div className="row">
                  <img
                    src="https://media.istockphoto.com/id/1324849113/photo/white-cotton-socks-on-white-background.jpg?b=1&s=612x612&w=0&k=20&c=-OAxu-ubOAj-YI4Z2VbYnKN_NP1bXFzZ87HfCs5T0SE="
                    alt=""
                  />
                  <button>
                    <Link to="/products?subCategory=Socks" className="link">
                      Socks
                    </Link>
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <img
                src="https://images.pexels.com/photos/9775733/pexels-photo-9775733.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
              <button>
                <Link to="/products?subCategory=Sweatpants" className="link">
                  Sweatpants
                </Link>
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Categories;
