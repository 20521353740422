import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Cart from '../Cart/Cart';
import { useSelector } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './Navbar.scss';

const Navbar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const products = useSelector((state) => state.cart.products);
  const accessToken = localStorage.getItem('accessToken');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMobile, setIsMobile] = useState(false); 

  console.log(isMobile);

  const handleResize = () => {
    const width = window.innerWidth;
    setIsMobile(width <= 700);
  };
  

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    navigate('/');
  };
      
  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="left">
          {isMobile ? (
            <>
             <Button
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    className="link"
                  >
                    <MenuIcon />
                  </Button>
                  <Menu
                    id="demo-positioned-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                 {accessToken ? (
                <div onClick={handleClose} className="item">
                 <Link onClick={handleLogout} className="link" to="/login">
                      LOGOUT
                    </Link>                  
                </div>
              ) : (
                <>
                  <div  onClick={handleClose} className="item">
                    <Link className="link" to="/login">
                      LOGIN
                    </Link>
                  </div>
                  <div className="item">
                    <Link  onClick={handleClose} className="link" to="/register">
                      REGISTER
                    </Link>
                  </div>
                </>
              )}
                    {accessToken && (
              <div className="item">
                <Link  onClick={handleClose} className="link" to="/orders">
                  ORDERS
                </Link>
              </div>
                    )}
              </Menu>
            </>
          ) : (
            <>
              {accessToken ? (
                <div className="item">
                  <Link onClick={handleLogout} className="link" to="/login">
                    LOGOUT
                  </Link>
                </div>
              ) : (
                <>
                  <div className="item">
                    <Link className="link" to="/login">
                      LOGIN
                    </Link>
                  </div>
                  <div className="item">
                    <Link className="link" to="/register">
                      REGISTER
                    </Link>
                  </div>
                </>
              )}
               {accessToken && (
              <div className="item">
                <Link className="link" to="/orders">
                  ORDERS
                </Link>
              </div>
              )}
            </>
          )}
        </div>
        <div className="center">
          <Link className="link" to="/">
            T-BRAND
          </Link>
        </div>
        <div className="right">
          <div className="icons">
            <Link>
              <SearchIcon />
            </Link>
            <div className="cartIcon" onClick={() => setOpen(!open)}>
              <ShoppingCartOutlinedIcon />
              <span>{products.length}</span>
            </div>
          </div>
        </div>
      </div>
      {open && <Cart open={open} setOpen={setOpen} />}
    </div>
  );
};

export default Navbar;
