import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./ForgetPassword.scss";
import axios from "axios";
import Spinner from "../../../components/spiner/Spiner";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please provide an email address", {
        className: "toast-message",
      });
      setError("Please provide all the required information.");
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      const res = await axios.post(
        "https://t-brand-api.onrender.com/api/auth/forgot-password",
        {
          email: email,
        }
      );
      navigate("/success");
      setEmail("");
      toast.success(
        res?.data?.message || "You have successfully created an account.",
        {
          className: "toast-message",
        }
      );
    } catch (error) {
      console.error(error?.message);
      toast.error(
        error?.response?.data?.message ||
          "An error occurred. Please try again later.",
        {
          className: "toast-message",
        }
      );
      setError(
        error?.response?.data?.message ||
          "An error occurred. Please try again later."
      );
    }

    setIsLoading(false);
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="forgetPassword">
      <div className="register-container">
        <div className="register-header">
          <h1>Forget Password </h1>
          <p>
            A Link will be sent to this email make sure you go and click for you
            to recover your password
            <br />
            <Link style={{ textAlign: "center" }} to="/login">
              Back to Login{" "}
            </Link>
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="register-input">
            <label htmlFor="email">Your Email Address</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {error && (
            <div
              style={{ color: "red", textAlign: "center", fontSize: " 20px" }}
              className="error-message"
            >
              {error}
            </div>
          )}
          <button type="submit" disabled={isLoading}>
            {isLoading ? "Loading..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;
