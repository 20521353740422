import React from "react";
import Categories from "../../components/Categories/Categories";
import Contact from "../../components/Contact/Contact";
import FeaturedProducts from "../../components/FeaturedProducts/FeaturedProducts";
import Slider from "../../components/Slider/Slider";
import "./Home.scss";
import Banner from "../../components/banner/Banner";
import OfferBanner from "../../components/banner/OfferBanner";

const Home = () => {
  return (
    <div className="home">
      <Slider />
      <FeaturedProducts type="Featured products" />
      <Categories />
      <FeaturedProducts type="Trending Products" />
      <Banner />
      <FeaturedProducts type="Latest products" />
      <OfferBanner />
      <FeaturedProducts type="Best Selling" />
      <Contact />
    </div> 
  );
};

export default Home;
