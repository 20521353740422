import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import "./Products.scss";
import image from "../../assets/6.jpg";

const Products = () => {
  const { search } = useLocation();
  const [products, setProducts] = useState([]);
  const [maxPrice, setMaxPrice] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [sort, setSort] = useState("");

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `https://t-brand-api.onrender.com/api/products/get-products${search}`,
        {
          params: {
            search: "",
            minPrice: minPrice,
            maxPrice: maxPrice,
            sort: sort,
          },
        }
      );

      setProducts(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterClick = () => {
    let updatedMaxPrice = maxPrice;
    let updatedMinPrice = minPrice;
    let updatedSort = sort;

    if (maxPrice !== "") {
      updatedMaxPrice = maxPrice * 0.5;
    }

    if (minPrice !== "") {
      updatedMinPrice = minPrice / 0.5;
    }

    if (sort !== "") {
      updatedSort = "";
    }

    setMaxPrice(updatedMaxPrice);
    setMinPrice(updatedMinPrice);
    setSort(updatedSort);
  };

  useEffect(() => {
    fetchProducts();
  }, [handleFilterClick]);

  const handleMaxPriceChange = (event) => {
    setMaxPrice(event.target.value);
  };

  const handleMinPriceChange = (event) => {
    setMinPrice(event.target.value);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <div className="products">
      <div className="left">
        <main>
          <div className="filterItem-time">
            <h2>Sort by Time</h2>
            <div className="inputItem-time">
              <select onChange={handleSortChange} value={sort}>
                <option value="">-- Select --</option>
                <option value="newest">Newest</option>
                <option value="oldest">Oldest</option>
              </select>
            </div>
          </div>

          <div className="filterItem-mixMax">
            <h2>Sort by price</h2>
            <section>
              <label htmlFor="maxPrice">Max</label>
              <input
                type="number"
                name="maxPrice"
                id="maxPrice"
                value={maxPrice}
                onChange={handleMaxPriceChange}
              />
            </section>
            <section>
              <label htmlFor="minPrice">Min</label>
              <input
                type="number"
                name="minPrice"
                id="minPrice"
                value={minPrice}
                onChange={handleMinPriceChange}
              />
            </section>
          </div>
        </main>
        <button type="submit" onClick={handleFilterClick}>
          Apply filter
        </button>{" "}
      </div>
      <div className="right">
        <img className="catImg" src={image} alt="" />
        <div className="productList">
          {products?.map((product) => (
            <div key={product?._id} className="card">
              <Link to={`product/${product?._id}`} className="link">
                <div className="image">
                  <span>New Season</span>
                  <div className="card-whichList">
                    <Link to="/wishList">
                      <FavoriteBorderOutlinedIcon />
                    </Link>
                  </div>{" "}
                  <>
                    <img src={product?.images[0]} alt="" className="mainImg" />
                    <img src={product?.images[1]} alt="" className="secondImg" />
                  </>
                </div>
                <h2>{product?.name}</h2>
                <div className="prices">
                  <h3>${product?.price}</h3>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Products;
