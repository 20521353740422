import React, { useState } from "react";
import "./ResetPassword.scss";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../../../components/spiner/Spiner";
import { toast } from "react-toastify";
import axios from "axios";

const ResetPassword = () => {
  const { resetToken } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!password || !confirmPassword) {
      setError("Please provide all the required information.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const userData = {
        password: password,
      };

      const response = await axios.put(
        `https://t-brand-api.onrender.com/api/auth/resetpassword/${resetToken}`,
        userData
      );

      if (response.status === 200) {
        toast.success("You have successfully reset your password.", {
          className: "toast-message",
        });
        navigate("/login");
      }
    } catch (error) {
      console.error(error);
      setError("Sorry the Token has Expired please resend it again");
      toast.error("Sorry the Token has Expired please resend it again", {
        className: "toast-message",
      });
    }

    setIsLoading(false);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="resetPassword">
      <div className="register-container">
        <div className="register-header">
          <h1>Reset Password</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="register-input">
            <label htmlFor="password">New Password</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          {error && (
            <div
              style={{
                color: "red",
                textAlign: "center",
                fontSize: "20px",
              }}
              className="error-message"
            >
              {error}
            </div>
          )}
          <button type="submit" disabled={isLoading}>
            {isLoading ? "Loading..." : "Reset"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
