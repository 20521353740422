import image from "./OFF.png";

const OfferBanner = () => {
  return (
    <div className="banner">
      <img src={image} alt="banner" />
      {/* <h1>Check out our best latest products and shop Now </h1> */}
    </div>
  );
};
export default OfferBanner;
