import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import Product from "./pages/Product/Product";
import Products from "./pages/Products/Products";
import "./app.scss";
import Register from "./pages/auth/Register/Register";
import Login from "./pages/auth/Login/Login";
import Orders from "./pages/Orders/Order";
import ResetPassword from "./pages/auth/resetPassword/ResetPassword";
import ForgetPassword from "./pages/auth/forgetPassword/ForgetPassword";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
// import PrivateRoute from "./components/privateRoute/PrivateRoute";
import Success from "./components/success/Success";
import Confirmation from "./pages/checkout/Confirmation";
import Checkout from "./pages/checkout/Checkout";

const App = () => {
  return (
    <Router>
      <div className="app">
        <Navbar />
        <Routes>
          <Route path="/success" element={<Success />} />
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/product/:id" element={<Product />} />
          <Route
            path="/products/product/:id/product/:id"
            element={<Product />}
          />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/product/:id/product/:id" element={<Product />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route
            path="/resetpassword/:resetToken"
            element={<ResetPassword />}
          />
          <Route path="/orders" element={<Orders />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/checkout/success" element={<Confirmation />} />
        </Routes>

        <Footer />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          className="toast-message"
          style={{ width: "10vw" }}
        />
      </div>
    </Router>
  );
};

export default App;
