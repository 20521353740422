import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Register.scss";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from "../../../components/spiner/Spiner";

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.email ||
      !formData.password ||
      !formData.password2 ||
      !formData.name
    ) {
      setError("Please provide all the required information.");
      return;
    }

    if (formData.password !== formData.password2) {
      setError("Passwords do not match.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const userData = {
        name: formData.name,
        email: formData.email,
        password: formData.password,
      };

      const res = await axios.post(
        "https://t-brand-api.onrender.com/api/auth/register",
        userData
      );
      // Save the token to local storage
    const accessToken = JSON.stringify(res.data);
    if (typeof window !== "undefined") {
      localStorage.setItem("accessToken", accessToken);
    }
      toast.success("You have successfully created an account.", {
        className: "toast-message",
      });
      navigate("/");

      setFormData({
        name: "",
        email: "",
        password: "",
        password2: "",
      });
    } catch (error) {
      console.error(error);
      setError("An error occurred. Please try again later.");
    }

    setIsLoading(false);
  };
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="register">
      <div className="register-container">
        <div className="register-header">
          <h1>Register</h1>
          <p>
            Already have an account? <Link to="/login">Log In</Link>
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="register-input">
            <label htmlFor="name">Full name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <label htmlFor="password2">Confirm Password</label>
            <input
              type="password"
              name="password2"
              value={formData.password2}
              onChange={handleChange}
            />
          </div>
          {error && (
            <div
              style={{ color: "red", textAlign: "center", fontSize: " 20px" }}
              className="error-message"
            >
              {error}
            </div>
          )}
          <button type="submit" disabled={isLoading}>
            {isLoading ? "Loading..." : "Register"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
