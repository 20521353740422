import React, { useEffect, useState } from "react";
import "./Order.css";
import axiosInstance from "../../axiosInstance";
import Spinner from "../../components/spiner/Spiner";

export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const token = JSON.parse(accessToken);
        const userId = token._id;
        const response = await axiosInstance.get(`/api/orders/find/${userId}`);
        setOrders(response.data);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching orders:", err);
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const calculateRevenue = () => {
    const revenue = orders.reduce(
      (total, transaction) => total + transaction.amount,
      0
    );
    return `$${revenue}`;
  };

  return (
    <>
      {orders.length === 0 ? (
        <h1>No Orders Available</h1>
      ) : (
        <section className="order-section" key={orders[0]._id}>
          <div className="order-container">
            <div className="order-row">
              <p className="order-heading">
                Thanks for your Order,{" "}
                <span className="order-name">
                  {orders[0].billingAddress.firstName}
                </span>
                !
              </p>
            </div>
            <div className="order-card">
              <div className="order-card-header">
                <h5 className="order-title">Receipt</h5>
                <p className="order-voucher">
                  Receipt Voucher: {orders[0]._id}
                </p>
              </div>
              <div className="order-card-body">
  {orders[0].products.map((product) => (
                  <div className="order-item" key={product._id}>
                    <div className="order-item-image">
                      <img
                        src={product.image}
                        className="order-img-fluid"
                        alt="Product"
                      />
                    </div>
                    <div className="order-item-details">
                      <p className="order-item-info"> {orders.name}</p>{" "}
                      <p className="order-item-info">Qty: {product.quantity}</p>{" "}
                      <p className="order-item-info-p"> {orders.status}</p>
                    </div>
                    <div className="order-item-track">
                      <div className="order-item-track-line"></div>
                      <div className="order-item-track-text">
                        <p>Out for delivery</p>
                        <p>Delivered</p>
                      </div>
                    </div>
                  </div>
                ))}
                <hr className="order-hr" />
                <div className="order-total">
                  <p className="order-total-label">Order Details</p>
                  <p>Shipping Addresses</p>
                  <p className="order-total-value">
                    {orders[0].shippingAddress.city}
                  </p>
                  <p className="order-total-value">
                    {orders[0].shippingAddress.country}
                  </p>
                  <p className="order-total-value">
                    {orders[0].shippingAddress.state}
                  </p>
                  <p className="order-total-value">
                    Total: ${calculateRevenue()}
                  </p>
                </div>
                <div className="order-info">
                  <p className="order-info-label">
                    Invoice Number: {orders[0]._id}
                  </p>
                  {/* Add more order info here */}
                </div>
              </div>
              <div className="order-card-footer">
                <p className="order-total-paid">
                  Total paid:{" "}
                  <span className="order-total-amount">
                    {calculateRevenue()}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
