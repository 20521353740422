import React from "react";
import "./Banner.scss";
import video from "./video.mp4";

const Banner = () => {
  return (
    <div className="banner">
      <video className="video" autoPlay loop muted>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <h1>Check out our best latest products and shop Now </h1>
    </div>
  );
};

export default Banner;
