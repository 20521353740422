import React from "react";
import "./Cart.scss";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { removeItem, resetCart } from "../../redux/cartReducer";
import { Link } from "react-router-dom";

const Cart = ({ open, setOpen }) => {
  const products = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();

  const totalPrice = () => {
    let total = 0;
    products.forEach((item) => {
      total += item.quantity * item.price;
    });
    return total.toFixed(2);
  };

  return (
    <div className="cart">
      <CloseIcon
        style={{ fontSize: "50px", cursor: "pointer", color: "red" }}
        onClick={() => setOpen(!open)}
      />
      <h1>Products in your cart</h1>
      {products?.map((item) => (
        <div className="item" key={item.id}>
          <img src={item.img} alt="" />

          <div className="details">
            <h1>{item.title}</h1>
            <p>{item.desc?.substring(0, 100)}</p>
            <div className="price">
              {item.quantity} x ${item.price}
            </div>
          </div>
          <DeleteOutlinedIcon
            className="delete"
            onClick={() => dispatch(removeItem(item.id))}
          />
        </div>
      ))}

      <div className="total">
        <span>SUBTOTAL</span>
        <span>${totalPrice()}</span>
      </div>
      {products.length !== 0 ? (
        <div>
          <button onClick={() => setOpen(!open)}>
            <Link to="/checkout">PROCEED TO CHECKOUT</Link>
          </button>
          <span
            style={{ textAlign: "center" }}
            className="reset"
            onClick={() => dispatch(resetCart())}
          >
            Reset Cart
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default Cart;
