import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./FeaturedProducts.scss";
import axiosInstance from "../../axiosInstance";

const FeaturedProducts = ({ type }) => {
  const [products, setProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axiosInstance.get("/api/products/get-products");
        setProducts(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProducts();
  }, []);
  useEffect(() => {
    if (type === "all") {
      setDisplayedProducts(products);
    } else {
      setDisplayedProducts(
        products.filter((product) => product.mainCategory === type)
      );
    }
  }, [type, products]);

  const displayCount = type === "all" ? products.length : 7;
  const displayedProductsSlice = displayedProducts.slice(0, displayCount);
  return (
    <div className="featuredProducts">
      <div className="top">
        <div className="top-arrow">
          <Link to={`/products?mainCategory=${type}`}>
            <h1> {type} </h1>
            <ArrowForwardIcon className="arrowIcon" />
          </Link>
        </div>
        <div className="top-desc">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
            ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
            accumsan lacus vel facilisis labore et dolore magna aliqua. Quis
            ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.
          </p>
        </div>
      </div>
      <div className="bottom">
        <div className="cards">
          {displayedProductsSlice.map((product) => (
            <div key={product._id} className="card">
              <Link to={`/product/${product._id}`} className="link">
                <div className="image">
                  <span>New Season</span>
                  <div className="card-whichList"></div>
                  <img src={product.images[0]} alt="" className="mainImg" />
                  <img src={product.images[1]} alt="" className="secondImg" />
                </div>
                <h2>{product.name}</h2>
                <div className="prices">
                  <h3>${product.price}</h3>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturedProducts;
