import React from "react";
import { Alert, AlertTitle, Box } from "@mui/material";

const EmailConfirmation = () => {
  return (
    <Box m="90px auto" width="80%" height="50vh">
      <Alert severity="success">
        <AlertTitle>Email Confirmation</AlertTitle>
        <p>An email has been sent to your registered account.</p>
        <p>
          Please check your inbox and follow the instructions provided to
          confirm your password.
        </p>
        <p>
          If you don't see the email in your inbox, please check your spam
          folder as well.
        </p>
        <p>
          Once you have confirmed your password, you'll be able to log in and
          access your account.
        </p>
        <p>
          If you need any assistance or have any questions, please feel free to
          contact our support team.
        </p>
        <strong>Thank you for choosing our platform!</strong>
      </Alert>
    </Box>
  );
};

export default EmailConfirmation;
